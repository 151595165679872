import React, { useContext, useState } from 'react'
import style from './ProjectsCategories.module.css'
import { useTranslation } from 'react-i18next'
import { LangContext } from '../context/LangContext'
import projectDesign1_1 from '../../assets/images/مشروع اس1.webp'
import projectHydro1_1 from '../../assets/images/elahmar.webp'
import projectGis1_1 from '../../assets/images/WhatsApp Image 2023-04-01 at 15.30.45.webp'
import projectSuper1_1 from '../../assets/images/IMG-20230802-WA0103.webp'
import projectInfra1_1 from '../../assets/images/WhatsApp Image 2024-03-05 at 7.39.58 AM.webp'
import projectTrans1_1 from '../../assets/images/21trans.webp'
import projectElectrical1_1 from '../../assets/images/WhatsApp Image 2024-07-28 at 8.49.54 PM.webp'
import projectEvaluation1_1 from '../../assets/images/020.webp'
import { Link } from 'react-router-dom'

export default function ProjectsCategories() {

  //   const [activeLink, setActiveLink] = useState('all')
    
  // const linkClick = (linkId) => {
  //   setActiveLink(linkId)
  // }
  
    const { t } = useTranslation()
    let {language}= useContext(LangContext)
    const site = sessionStorage.getItem('desiredPage')

  const companyProjects = [
    {
      id: 'architecturalProjects',
      name: t("navArchitectural"),
      images: [projectDesign1_1]
    },
    {
      id: 'hydrologicalProjects',
      name: t("navHydrological"),
      images: [projectHydro1_1]
    },
    {
      id: 'gisProjects',
      name: t("navGIS"),
      images: [projectGis1_1]
    },
    {
      id: 'supervisionProjects',
      name: t("navSupervision"),
      images: [projectSuper1_1]
    },
    {
      id: 'infrastructureProjects',
      name: t("navInfrastructure"),
      images: [projectInfra1_1]
    },
    {
      id: 'transportProjects',
      name: t("navTransport"),
      images: [projectTrans1_1]
    },
    {
      id: 'electricalProjects',
      name: t("navElectrical"),
      images: [projectElectrical1_1]
    },
    {
      id: 'evaluationProjects',
      name: t("navEvaluation"),
      images: [projectEvaluation1_1]
    },
  ]

  return <>
  <div dir={language==='en'? '':'rtl'} className={`py-5 container-md w-90 px-0`}>
    <ul data-aos="fade-up" data-aos-delay="300" className={`nav pt-4 w-100 justify-content-center d-none d-md-flex`}>
          {companyProjects.map((project) => (
              <li className="nav-item" key={project.id}>
                <Link to={`/projects/${project.id}`} className={`nav-link nav-link-collapse text-decoration-none border-bottom text-center py-3 px-2 font-small text-hover`}>{project.name}</Link>
              </li>
            ))}
          </ul>
          <div data-aos="fade-up" data-aos-delay="700" className={`d-md-none text-center mt-md-5 mt-3 py-4`}>
            <div className='dropdown'>
              <button className={`btn dropdown-toggle border-0 bg-body-secondary p-3 dropdown-select w-100 ${language === 'en' ? '' : 'text-end'} cursor-pointer`} type="button" data-bs-toggle="dropdown" aria-expanded="false">{t('select')}</button>
              <ul className={`dropdown-menu w-100 ${language === 'en' ? '' : 'text-end'}`}>
                {companyProjects.map((project) => <li><Link className="dropdown-item" to={`/projects/${project.id}`}>{project.name}</Link></li>)}
              </ul>
            </div>
          </div>
    <div className="row justify-content-center g-xl-5 g-lg-4 g-3 px-0 mt-2 mb-5">
      {companyProjects.map((project) => <div data-aos="zoom-in" data-aos-delay="1000" data-aos-duration="2000" key={project.id} className={site==='company'? 'col-lg-3 col-md-4 col-sm-6':'col-lg-6'}>
          <Link to={site==='company'? `/projects/${project.id}`:`/projectDetails/${project.id}`} className='position-relative project trans3'>
            <div className={site==='company'? 'square':'portrait'}>
              <img loading='lazy' className='w-100 h-100 object-center object-cover' src={project.images[0]} alt="project" />
            </div>
            <div className="position-absolute py-2 overflow-hidden linear-gradient-card z-1 top-0 bottom-0 start-0 end-0 d-flex align-items-end">
              <div className={`w-100 px-xxl-5 px-xl-4 px-3 ${language === 'en'? '':'text-end'}`}>
                <h5 className='text-white fs-6 project-name fw-semibold'>{t('achieveProjects')}&nbsp;{project.name}</h5>
                <button className={`btn text-white px-0 text-hover pt-xl-4 pb-sm-4 pt-2 pb-3 d-flex w-100 align-items-center`}><p>{site==='company'? t('forMore'):t('details')}</p><i className={`fa-solid fa-arrow-left ${language==='en'? 'rotateY':''} pe-2 pt-1`}></i></button>
              </div>
            </div>
          </Link>
        </div>)}
      </div>
    </div>
  </>
}
